import styled from "styled-components"
import media from "styled-media-query"
import Img from "gatsby-image"


export const BonusWrapper = styled.section``

export const BonusHeader = styled.div`
  padding: 2.5rem 0;
  background-color: var(--black_background_contrast);
`

export const BonusBody = styled.div`
  width: 100%;
  display: block;
  padding: 0 2.5rem 2.5rem;
  background-color: var(--black_background_contrast);
`

export const BonusTitle = styled.h1`
  font-size: 3rem;
  text-align: center;
  margin: 1.5rem;
  color: var(--white);

  ${media.lessThan("large")`
    font-size: 2.5rem;
  `}
`

export const BonusSubtitle = styled.h2`
  font-size: 2rem;
  color: var(--dark_orange);
`

export const BonusText = styled.p`
  line-height: 1.7;
  letter-spacing: 0.069rem;
  font-weight: 300;
  margin: 1.5rem 0;
  color: var(--white);
`

export const BonusBold = styled.strong`
  color: var(--dark_orange);
  font-weight: 700;
`

export const BonusDescription = styled.p`
  line-height: 1.7;
  width: 100%;
  padding: .75rem 2.5rem 0;
  letter-spacing: 0.069rem;
  display: block;
  margin: 1rem auto;
  color: var(--white);
`
