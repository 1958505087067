import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/Layout"
import SEO from "../../components/seo"

import * as S from "../../components/Bonus/styled"

const BonusPage = () => {
  return (
    <Layout visibleSideBar={false}>
      <SEO
        title="Bônus Digitais e Comunidade VED"
        description="Aqui você encontra os detalhes e a descrição sobre a comunidade VED e como fazer parte da melhor comunidade de Marketing Digital do Brasil"
      />
      <S.BonusWrapper>
        <S.BonusHeader>
          <S.BonusTitle>Meus Bônus Platinum</S.BonusTitle>
          <S.BonusDescription>
            Nessa seção é onde eu te mostro os benefícios de quando você compra algum curso que eu te indico. Não adianta eu ser hipócrita e fingir que não quero te vender nada. Até porque todos sabemos que para realizar grandes coisas, principalmente dentro do empreendedorismo é de fato necessário você investir em você mesmo e <S.BonusBold>conhecimento é a chave para isso.</S.BonusBold> <br/><br/>
            Mas então para qual finalidade é essa secão de bônus?<br/><br/>
            Essa parte é a forma que <S.BonusBold>eu tenho de agradecer</S.BonusBold> vocês pela confiança em nosso trabalho que vem sendo realizado e mais que isso, é a forma que <S.BonusBold>eu consigo te apoior e indicar o caminho</S.BonusBold> de uma forma mais acertiva para você que assim como eu busca <S.BonusBold>viver do digital.</S.BonusBold> <br/><br/>
            Quais são os Bônus então? Abaixo eu descrevo cada bônus que você irá ganhar e como ter acesso.
          </S.BonusDescription>
        </S.BonusHeader>
        <S.BonusBody>
          <S.BonusSubtitle>Mentoria</S.BonusSubtitle>
          <S.BonusText>É isso mesmo que você está lendo, eu vou ceder 2 horas minha para te dar toda a atenção que você necessita. Normalmente eu cobro R$147,00 a hora da minha mentoria, mas eu quero muito que você consiga prosperar e por isso vou te ajudar nesse caminho. Minha recomendação é que você deve usar essas 2 horas de forma distintas, a primeira nos primeiros momentos que você estiver pondo em prática aquilo que está aprendendo e a segunda quando tiver tendo seus primeiros resultados e assim poderemos discutir melhor como evoluir e seguir ainda mais para uma escalada em sua jornada.</S.BonusText>
          <S.BonusSubtitle>Ebook Produtividade</S.BonusSubtitle>
          <S.BonusText>Não é nenhuma novidade que eu e você adoramos uma desculpa para poder enrolar um pouco mais no nosso dia a dia, e acredite isso não é apenas com nós. Todo bom ser humano adora dar uma descansada e protelar sempre que possível, nosso sistema é feito para isso, relaxar e economizar energia, por isso nesse ebook eu te mostro como você pode se organizar melhor e ter mais produtividade no seu negócio digital e assim diminuir com aquela vontade de protelar.<br/>
          Junto desse ebook eu também te dou algumas planilhas que eu mesmo utilizo no meu dia a dia para gerenciar meu negócio digital.</S.BonusText>
          <S.BonusSubtitle>Ebook Escolhendo bons produtos</S.BonusSubtitle>
          <S.BonusText>Toda jornada tem um início e todo caminho uma dificuldade. A jornada de um afiliado se inicia em uma busca de um bom produto, por isso nesse ebook eu vou te mostrar passo a passo como escolher um bom produto para que você inicie sua jornada no marketing digital, dando exemplos e mostrando o método que eu utilizo quando estou escolhendo meus próprios produtos para meu projeto.</S.BonusText>
          <S.BonusSubtitle>Dicas para o Youtube</S.BonusSubtitle>
          <S.BonusText>O youtube hoje é uma das melhores plataformas para se criar conteúdo hoje em dia, e caso você tenha essa aspiração e desejo, nesse bônus eu vou te dar dicas de como começar um canal e conseguir fazer ele crescer, mesmo que seja de um nicho bem complicado e concorrido como marketing digital.</S.BonusText>
          <S.BonusSubtitle>Comece Melhor no GPA</S.BonusSubtitle>
          <S.BonusText>Nesse bônus em aulas gravadas eu vou te ensinar a identificar qual o seu momento dentro do marketing digital. A partir desse momento nós iremos traçar a melhor estratégia de uso para você dentro do curso do GPA.</S.BonusText>
          <S.BonusSubtitle>Comunidade VED</S.BonusSubtitle>
          <S.BonusText>A comunidade VED busca a ser um local onde pessoas de todos os níveis podem se encontrar e trocar experiências através de um grupo de telegram. <br/><br/>
          Também terá acesso a uma área de membros onde ficam disponíveis as lives que eu faço todo mês no Youtube e apenas quem tem acesso a comunidade consegue acesso a elas depois que aconteceu. <br/><br/></S.BonusText>
        </S.BonusBody>
      </S.BonusWrapper>
    </Layout>
  )
}

export default BonusPage
